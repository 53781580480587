.upsales-page {
  height: 100%;
  background-color: #eee;
}

.upsales-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.upsales-container__graph {
  width: 40vw;
  height: 40vw;
}

.upsales-container__list {
  width: 55vw;
  height: 40vw;
}

.table .table--centered-text {
  vertical-align: middle;
}

// Shortens string and adds ... to end
// Not used, but can be useful in the future
// .table__description {
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
// }

.profile-picture {
  border-radius: 50%;
  height: 3rem;
}
