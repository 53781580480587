.graph-page {
  height: 100%;
}

.graph-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  background-color: #eee;
}

.graph-container__graph {
  width: 50vw;
  height: 50vw;
}
